import React, { Component } from "react";

export default class BlockchainImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="980.494"
        height="751.895"
        data-name="Layer 1"
        viewBox="0 0 980.494 751.895"
      >
        <path fill="#3f3d56" d="M313.494 117H315.494V224H313.494z"></path>
        <path
          fill="#3f3d56"
          d="M313.494 255.85H315.494V349.999H313.494z"
        ></path>
        <path fill="#3f3d56" d="M310.494 365H465.494V367H310.494z"></path>
        <path fill="#3f3d56" d="M244.494 392H246.494V477H244.494z"></path>
        <path fill="#3f3d56" d="M262.494 492H497.494V494H262.494z"></path>
        <path fill="#3f3d56" d="M691.494 49H693.494V150H691.494z"></path>
        <path fill="#3f3d56" d="M529.46 166H676.494V168H529.46z"></path>
        <path fill="#3f3d56" d="M512.494 182H514.494V317H512.494z"></path>
        <path
          fill="#3f3d56"
          d="M314.494 257a17 17 0 1117-17 17.019 17.019 0 01-17 17zm0-32a15 15 0 1015 15 15.017 15.017 0 00-15-15zM246.494 510a17 17 0 1117-17 17.019 17.019 0 01-17 17zm0-32a15 15 0 1015 15 15.017 15.017 0 00-15-15z"
        ></path>
        <circle
          cx="315.494"
          cy="366"
          r="16"
          fill={theme.imageHighlight}
        ></circle>
        <path fill="#3f3d56" d="M728.494 239H894.494V241H728.494z"></path>
        <path
          fill="#3f3d56"
          d="M711.494 255.85H713.494V349.999H711.494z"
        ></path>
        <path fill="#3f3d56" d="M562.494 365H695.494V367H562.494z"></path>
        <path fill="#3f3d56" d="M796.494 374H934.494V376H796.494z"></path>
        <path fill="#3f3d56" d="M780.494 392H782.494V477H780.494z"></path>
        <path fill="#3f3d56" d="M529.494 492H764.494V494H529.494z"></path>
        <path fill="#3f3d56" d="M512.494 415H514.494V477H512.494z"></path>
        <circle
          cx="712.494"
          cy="240"
          r="16"
          fill={theme.imageHighlight}
        ></circle>
        <path
          fill="#3f3d56"
          d="M692.494 183a17 17 0 1117-17 17.019 17.019 0 01-17 17zm0-32a15 15 0 1015 15 15.017 15.017 0 00-15-15zM513.494 183a17 17 0 1117-17 17.019 17.019 0 01-17 17zm0-32a15 15 0 1015 15 15.017 15.017 0 00-15-15zM780.494 393a17 17 0 1117-17 17.019 17.019 0 01-17 17zm0-32a15 15 0 1015 15 15.017 15.017 0 00-15-15z"
        ></path>
        <circle
          cx="780.494"
          cy="493"
          r="16"
          fill={theme.imageHighlight}
        ></circle>
        <path
          fill="#3f3d56"
          d="M513.494 510a17 17 0 1117-17 17.019 17.019 0 01-17 17zm0-32a15 15 0 1015 15 15.017 15.017 0 00-15-15zM711.494 383a17 17 0 1117-17 17.019 17.019 0 01-17 17zm0-32a15 15 0 1015 15 15.017 15.017 0 00-15-15zM246.494 342a20 20 0 1020 20 20.059 20.059 0 00-20-20zm0 6a6 6 0 11-6 6 6.02 6.02 0 016-6zm0 28.885a14.57 14.57 0 01-12-6.404c.096-4 8-6.202 12-6.202s11.904 2.202 12 6.202a14.594 14.594 0 01-12 6.404zM315.494 69a20 20 0 1020 20 20.059 20.059 0 00-20-20zm0 6a6 6 0 11-6 6 6.02 6.02 0 016-6zm0 28.885a14.57 14.57 0 01-12-6.404c.096-4 8-6.202 12-6.202s11.904 2.202 12 6.202a14.594 14.594 0 01-12 6.404zM692.494 0a20 20 0 1020 20 20.059 20.059 0 00-20-20zm0 6a6 6 0 11-6 6 6.02 6.02 0 016-6zm0 28.885a14.57 14.57 0 01-12-6.404c.096-4 8-6.202 12-6.202s11.904 2.202 12 6.202a14.594 14.594 0 01-12 6.404zM922.494 219a20 20 0 1020 20 20.059 20.059 0 00-20-20zm0 6a6 6 0 11-6 6 6.02 6.02 0 016-6zm0 28.885a14.57 14.57 0 01-12-6.404c.096-4 8-6.202 12-6.202s11.904 2.202 12 6.202a14.594 14.594 0 01-12 6.404zM960.494 354a20 20 0 1020 20 20.059 20.059 0 00-20-20zm0 6a6 6 0 11-6 6 6.02 6.02 0 016-6zm0 28.885a14.57 14.57 0 01-12-6.404c.096-4 8-6.202 12-6.202s11.904 2.202 12 6.202a14.594 14.594 0 01-12 6.404z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M514.772 353.667l-2.05 8.215c2.324.579 9.484 2.94 10.644-1.708 1.209-4.847-6.271-5.928-8.594-6.507zM511.69 366.028l-2.26 9.058c2.789.693 11.392 3.455 12.664-1.655 1.329-5.328-7.615-6.707-10.404-7.403z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M524.034 329.212a37.493 37.493 0 1027.308 45.45 37.487 37.487 0 00-27.308-45.45zm7.462 31.037c-.541 3.653-2.565 5.422-5.254 6.04 3.69 1.922 5.57 4.87 3.78 9.98-2.22 6.345-7.497 6.88-14.512 5.553l-1.703 6.824-4.115-1.025 1.68-6.733q-1.644-.408-3.28-.85l-1.685 6.764-4.11-1.026L504 378.94c-.961-.246-1.937-.508-2.933-.757l-5.354-1.336 2.042-4.71s3.032.808 2.99.748a1.496 1.496 0 001.886-.978l2.69-10.787c.152.037.299.073.435.108a3.496 3.496 0 00-.427-.137l1.919-7.701a2.19 2.19 0 00-1.917-2.392c.065-.044-2.988-.743-2.988-.743l1.095-4.395 5.675 1.417-.005.02c.853.213 1.732.414 2.628.618l1.686-6.757 4.112 1.025-1.652 6.625c1.104.252 2.215.506 3.297.775l1.64-6.581 4.115 1.025-1.685 6.76c5.194 1.789 8.993 4.47 8.247 9.462z"
        ></path>
        <path
          fill="#e6e6e6"
          d="M152 733.294c-2.846 25.876-152.226 23.704-152-.002 2.846-25.874 152.226-23.702 152 .002z"
        ></path>
        <path
          fill="#ffb8b8"
          d="M103.754 668.156L109.81 681.276 120.911 680.267 127.976 657.054 116.874 650.999 103.754 668.156z"
        ></path>
        <path
          fill="#ffb8b8"
          d="M65.642 698.373L65.403 707.516 82.56 708.525 82.56 697.424 65.642 698.373z"
        ></path>
        <path
          fill={theme.dark}
          d="M71.458 531.908L75.495 612.647 65.403 700.451 95.68 704.488 114.856 593.472 120.911 517.778 71.458 531.908z"
        ></path>
        <path
          fill={theme.dark}
          d="M24.024 502.64s-12.111 79.73 13.12 105.97 59.546 72.666 59.546 72.666l24.221-30.277-61.564-65.601 12.111-44.407 49.453-23.213-8.074-55.508-72.665-1.01z"
        ></path>
        <circle cx="83.569" cy="295.744" r="22.203" fill="#ffb8b8"></circle>
        <path
          fill="#ffb8b8"
          d="M64.394 300.79s-1.01 31.287-6.056 35.324 18.167 21.194 18.167 21.194l15.138-24.221v-22.204z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M74.486 343.179L62.233 324.677 55.31 334.096 45.218 362.355 53.292 472.362 100.726 473.371 106.782 347.216 91.643 329.05 74.486 343.179z"
        ></path>
        <path
          fill={theme.jacketColor}
          d="M125.958 350.244l8.074-1.01s2.018 1.01 3.027 8.074 13.12 69.638 13.12 69.638l-16.147 76.703-18.167-24.222 11.102-42.388-11.102-42.389z"
        ></path>
        <path
          fill={theme.jacketColor}
          d="M23.015 349.234L16.959 349.234 1.821 430.983 15.95 494.566 30.079 471.353 26.042 446.122 28.061 423.919 35.126 410.798 23.015 349.234z"
        ></path>
        <path
          fill={theme.dark}
          d="M112.837 677.239s-6.055-2.019-6.055 2.018 4.037 16.148 4.037 16.148-6.056 37.342 3.028 35.324 16.148-19.176 17.157-24.222 6.055-31.287 6.055-31.287 8.074-19.175 3.028-20.185-19.176-5.046-19.176-5.046 8.074 25.231-8.074 27.25zM83.57 708.525s-16.149-10.092-19.176-2.018a84.8 84.8 0 00-4.037 18.166s-4.037 13.12 12.11 11.102 14.13-2.018 15.14-8.074-4.038-19.176-4.038-19.176zM70.45 295.744l4.036-1.009s3.028-17.157 10.093-15.138 25.23 4.037 25.23-4.037-17.156-15.14-27.249-14.13-27.25 4.037-26.24 19.176 7.521 29.865 7.521 29.865l.526-8.975z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M91.643 355.29L74.486 343.179 91.643 329.05 97.6 336.197 91.643 355.29z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M59.347 354.281L74.486 343.179 62.375 325.013 55.31 334.096 59.347 354.281z"
        ></path>
        <path
          fill={theme.jacketColor}
          d="M133.022 349.234l-32.296-13.12-7.569-5.55-22.708 100.42-15.139-45.417 4.542-58.031-41.884 21.698 14.13 68.63 2.018 26.24-6.055 21.194s-21.194 15.138-14.13 31.286 15.14 17.157 15.14 17.157 34.313-32.296 36.332-40.37 5.046-22.203 5.046-22.203 17.157 64.592 37.342 63.583 20.185-22.204 20.185-22.204l-5.046-22.203-8.074-23.213 4.037-38.351z"
        ></path>
      </svg>
    );
  }
}
