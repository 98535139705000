import React, { Component } from "react";

export default class ProjectMgmtImg extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="552.941"
        height="367.92"
        viewBox="0 0 552.941 367.92"
      >
        <path
          fill="#e6e7e8"
          d="M213.78 33.24h-67.085c-2.301 0-4.174-1.873-4.174-4.175s1.873-4.174 4.174-4.174h67.085c2.301 0 4.174 1.873 4.174 4.174s-1.873 4.174-4.174 4.174zM266.133 47.366H146.695c-2.301 0-4.174-1.872-4.174-4.174s1.873-4.173 4.174-4.173h119.438c2.302 0 4.174 1.872 4.174 4.173s-1.872 4.174-4.174 4.174zM266.133 61.366H146.695c-2.301 0-4.174-1.872-4.174-4.174s1.873-4.173 4.174-4.173h119.438c2.302 0 4.174 1.872 4.174 4.173s-1.872 4.174-4.174 4.174z"
        ></path>
        <path
          fill="#3f3d56"
          d="M278.785 134.019v-2c-23.085 0-43.245-18.858-48.605-45.126l8.699 2.238c-4.207-3.986-8.458-10.659-11.094-16.112-1.557 5.854-4.466 13.211-7.842 17.922l8.23-3.899c5.473 27.329 26.499 46.977 50.612 46.977z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M270.053 57.48c0 2.206-1.795 4-4 4h-52c-2.206 0-4-1.794-4-4s1.794-4 4-4h52c2.205 0 4 1.794 4 4z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M35.904 280.297c-.792-2.756-2.147-5.107-3.761-6.784l-5.864-26.73-12.781 3.13 7.324 26.779c-.481 2.265-.385 4.97.407 7.726 1.808 6.297 6.56 10.48 10.612 9.342 4.052-1.138 5.871-7.166 4.063-13.463z"
        ></path>
        <path
          fill={theme.jacketColor}
          d="M34.136 104.956s-13.805-.476-21.805 8.524C3.165 124.676-3.1 168.547 1.603 171.5l12.743 90.486 18.17-2.497-4.663-108.57 6.283-45.964z"
        ></path>
        <path
          fill="#2f2e41"
          d="M127.33072 257.47993L124.02328 367.91723 76.52328 367.91723 74.60332 325.34722 66.16331 367.91723 15.25333 367.91723 21.60331 294.73723 28.1633 279.34722 32.60331 268.91723 127.33072 257.47993z"
        ></path>
        <path
          fill={theme.jacketColor}
          d="M89.603 86.917l-33-2-11 14c-9.5 2.034-18.08 4.66-24 9 1.416 41.926-1.694 96.442 11 107L21.331 294.48s4.546-3.895 12.14-2.52c2.623.475 5.79 6.385 9.013 6.743 24.77 2.752 64.938-.078 84.847-41.223l-7.728-59.563 2-87c-6.252-4.942-14.025-8.154-23-10l-9-14z"
        ></path>
        <path
          fill="#ffb6b6"
          d="M153.134 62.578c.358 3.467-.097 6.728-1.144 9.342l5.497 32.812-15.69 2.159-3.887-33.517c-1.559-2.345-2.67-5.444-3.028-8.91-.818-7.92 2.604-14.763 7.645-15.284s9.789 5.478 10.607 13.398z"
        ></path>
        <path
          fill={theme.jacketColor}
          d="M100.97 113.424c-1.642 2.424-1.185 5.148-1.577 8.422-1.665 13.896 24.965 54.96 43.39 59.688 2.418.62 3.796 3.228 6.236 3.753 7.23 1.554 13.961-4.185 13.57-11.57l-5.004-94.707-21.027 7.172 1.345 49.17c-.134-10.186-9.487-20.558-16.572-24.872 0 0-14.351-5.922-20.36 2.944z"
        ></path>
        <circle cx="76.528" cy="53.262" r="29.068" fill="#ffb6b6"></circle>
        <path
          fill="#2f2e41"
          d="M103.603 31.917c-.25.06 3.827-3.913 4-4 4-2-.11 17.55-3 17-7.11-1.35-10.18-4.03-12.94 2.66-.77 1.86-1.25 3.96-2.75 5.29-2.06 1.81-5.48 1.66-7.16 3.82-1.35 1.74-.96 4.3.04 6.26 1 1.97 2.52 3.64 3.43 5.65 1.02 2.26-3.33 9.45-4.99 13.49v.01l-.01.024c-.44 1.06-1.489-1.993-2.595-2.298-1.325-.364-3.276 2.762-5.385 2.194-.01 0-.02-.01-.03-.01-2.01-.56-4.25-1.16-6.47-1.76h-.01c-6.25-1.7-7.83.74-8.13.67l-3.88-4.06c-1.331-.97-1.18-4.1-2.12-4.94-.768-.687-2.682.901-3.246.24-2.766-3.24-3.403-6.48-5.034-12.88-1.49-5.85-4.58-23.36 1.77-24.15 5.99-.75 1.61-9.09 7.64-8.78-.36-1.52.31-3.15 1.4-4.27 1.08-1.13 2.52-1.83 3.97-2.43 7.15-2.92 5.96-5.43 13.5-3.73.75-1.05 10.368-3.787 11.728-3.437.1.02-2.098 4.287 3.272 3.437-.2 1.23 2.728-1.437 3 3 5.728-3.437 8.81 4.79 9 6 .25 1.51-2.03.74-.51.97.9.14 2.44 1.97 2.29 2.88.65-.88 1.3-1.75 1.96-2.63.12.01.23.03.35.05 3.04.58-.445 10.518-.32 7.42.23-5.69 4.26-2.36 1.23-1.69zM474.37 25.958a1184.401 1184.401 0 01-3.52 26.443c-.517 3.575-1.064 7.195-2.502 10.508-.981 2.261-2.356 4.326-3.724 6.378-1.615 2.424-3.258 4.879-5.452 6.795s-5.045 3.261-7.95 3.036c7.707-5.187 12.886-13.961 13.704-23.215.385-4.35-.143-8.722-.244-13.088-.101-4.365.264-8.883 2.278-12.757s5.993-6.976 10.358-6.866l-2.948 2.766z"
        ></path>
        <path
          fill="#a0616a"
          d="M396.268 214.23c-6.27 5.086-13.977 5.974-17.214 1.984s-.777-11.348 5.494-16.434c2.745-2.226 5.765-3.647 8.565-4.208l26.84-21.216 9.688 12.807-27.488 19.556c-1.126 2.624-3.14 5.285-5.885 7.512z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M401.338 185.428l39.993-37.948 4-3s22.957-22.367 28.902-22.879a11.348 11.348 0 017.157 1.79c3.427 2.222 5.201 5.88 5.201 9.566a11.3 11.3 0 01-3.78 8.455l-36.614 32.87-15.576 13.981-19.37 17.394-1.97-4.01-6.66-13.593-1.283-2.626z"
        ></path>
        <path
          fill="#a0616a"
          d="M486.853 159.746l-40.043 12.265c6.704 1.888 9.51 42.74 3.272 44.008 0 0 66.364 4.72 62.743 0-4.414-5.754 1.506-43.991 4.329-44.008l-30.301-12.265z"
        ></path>
        <path
          fill="#2f2e41"
          d="M540.136 367.92l-3.5-42.24c0-15.11-.98-28.76-2.55-40.93-6.29-48.85-22.06-73.89-22.06-73.89h-59c-.08.09-.16.16-.24.25l-.01.01c-13.92 14.63-21.16 51.42-24.38 73.63-1.53 10.57-2.15 17.84-2.15 17.84l-5.77 51.28 4.17 14.05h34.33l-.54-3.01 22.09-80.16.27-.98.24.98 20.48 83.17h38.62z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M454.235 135.38l12.468-25.168 10.505-18.284 8.495-8.657h23.249l2.164 8.657 12.986 7.214 4.593 36.073-16.364 78.265c-19.48-15.872-59.628-20.268-59.628-20.268l-6.506-18.93-4.3-22.806-.519-2.727 12.857-13.37z"
        ></path>
        <path
          fill="#a0616a"
          d="M452.74 180.905c-8.07-.229-14.495-4.578-14.35-9.713.146-5.136 6.807-9.114 14.877-8.885 3.533.1 6.75.99 9.24 2.39l34.184 1.4-.999 16.027-33.593-3.08c-2.564 1.256-5.827 1.962-9.36 1.861z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M496.457 126.198l32.246 32.014-56.168 5.752-13.053 18.36 78.288 2.596c7.064 2.516 14.588-2.366 15.17-9.842.195-2.524-15.888-66.572-25.625-72.518-11.412-6.969-30.858 23.638-30.858 23.638l6.369-10.603-6.37 10.603z"
        ></path>
        <circle
          cx="492.526"
          cy="51.433"
          r="26.333"
          fill="#a0616a"
          transform="rotate(-89.076 492.526 51.433)"
        ></circle>
        <path
          fill="#2f2e41"
          d="M550.33 133.48c-.66 2.5-5.8 3.79-7 7-3.82 2.18-1.75-15.63-3.29-16.8-1.19-.89-1.51 1.26-1.78-.59-1.02-6.94-7.69-5.39-12.63-6.51-.56-1.09-.99-2.15-1.27-3.18-.5-1.86-2.78-2.51-4.13-1.14-.61-.54-1.05-2.45-1.48-4.36-.31-1.39-.62-2.78-.98-3.66l-3.44-7.76 1.11 5.78c-2.25-.81-4.52.03-5.83-2.75-1.68-3.57-2.35-7.75-1.13-11.5 1.11-3.4 2.98-10.21 5.09-15.85-2.39 1.73-5.26 2.99-8.59 3.62 1.18-2.24 2.38-4.52 2.88-7.01.49-2.49.19-5.26-1.39-7.24-1.3-1.63-3.22-1.22-4.84-2.53-1.26-1.02-2.35-3.63-3.11-5.06 3.24 10.77 5.69 22.21 1.57 32.49-2.85 7.12-8.85 12.91-16.05 15.54 3.83-2.74 7.57-5.66 10.44-9.38 3.07-3.99 5.07-9.03 4.46-14.03-.96-7.85-15.52-32.68-17.31-37.56.98 4.77.6 8.49-1.98 10.54-.67-6.92-8.21-6.29-12.32-9.06 0 0-3.18-17.97 4.28-18.08 3.6-.05 7.37-8.51 10.64-10.02 5.22-2.41 8.42-.77 14.21-.59 5.78.17 11.33 2.66 14.86 7.23 1.85 2.39 2.53.49 5.31 1.98 2.64 1.41 4.42.1 7.41.19 5.99.16 11.8 3.36 15.16 8.31 3.36 4.96 5.43 4.5 4.1 12.17-.02.09-.02.27 0 .54.73 12.08-1.14 24.19-5.93 35.31-1.48 3.42 5.76 6.95 5.75 11.22-.01 4.96-8 9.48-7.26 11.6 2.12 10.21 14.03 7.94 11.22 25.03-.23 1.39 3.69 4.44 3.25 6.11zM477.378 102.21c.22-.07.44-.15.66-.24l-.83.59.17-.35z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M514.47 17.96c-2.864.426-5.774-4.327-4.148-10.917 3.545 5.42 5.356 11.52 5.641 18.222l-1.91.592.417-7.897z"
        ></path>
        <path
          fill={theme.imageHighlight}
          d="M529.106 11.808c-1.955 6.927-11.882 11.563-13.636 9.152l-.2 4.13-1.227-1.578c3.56-5.294 8.413-9.356 15.063-11.704z"
        ></path>
        <g fill="#e6e7e8">
          <path d="M311.12 198.594a4.179 4.179 0 014.175-4.174h67.085c2.301 0 4.174 1.872 4.174 4.174s-1.873 4.174-4.174 4.174h-67.085a4.179 4.179 0 01-4.174-4.174zM311.12 226.594a4.179 4.179 0 014.175-4.174h67.085c2.301 0 4.174 1.872 4.174 4.174s-1.873 4.174-4.174 4.174h-67.085a4.179 4.179 0 01-4.174-4.174zM262.941 216.895c-2.301 0-4.173-1.873-4.173-4.174s1.872-4.174 4.173-4.174H382.38c2.301 0 4.174 1.873 4.174 4.174s-1.873 4.174-4.174 4.174H262.94z"></path>
        </g>
        <g>
          <path
            fill="#3f3d56"
            d="M254.062 176.947l-8.23-3.899c3.376 4.711 6.284 12.069 7.841 17.923 2.637-5.453 6.887-12.126 11.094-16.112l-8.698 2.238c5.36-26.268 25.52-45.126 48.604-45.126v-2c-24.113 0-45.139 19.648-50.611 46.976z"
          ></path>
          <path
            fill={theme.imageHighlight}
            d="M291.941 216.97h-52c-2.205 0-4-1.793-4-4s1.795-4 4-4h52c2.206 0 4 1.795 4 4s-1.794 4-4 4z"
          ></path>
        </g>
        <circle
          cx="291.673"
          cy="132.657"
          r="15.657"
          fill="#3f3d56"
          transform="rotate(-80.783 291.673 132.657)"
        ></circle>
        <circle cx="206.673" cy="9.657" r="9.657" fill="#e6e7e8"></circle>
        <circle cx="319.673" cy="176.657" r="9.657" fill="#e6e7e8"></circle>
      </svg>
    );
  }
}
