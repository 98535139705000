import React from "react";
import "./LoaderLogo.css";

class LogoLoader extends React.Component {
  render() {
    const theme = this.props.theme;
    return (
      <svg
        className="raw_logo"
        width="70%"
        height="50%"
        viewBox="0 0 406 209"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* <path
          class="myPentagon"
          d="M44.97,12.84h-17.2L0,49.37L27.77,85.9h17.2L17.2,49.37L44.97,12.84L44.97,12.84z"
          stroke={theme.body}
          stroke-width="3"
        />
        <path
          class="myPentagon"
          d="M77.91,12.84h17.2l27.77,36.53 L95.11,85.9h-17.2l27.77-36.53L77.91,12.84L77.91,12.84z"
          stroke={theme.body}
          stroke-width="3"
        />
        <path
          class="myPentagon"
          d="M70.17,0.04l5.96,1.39c0.94,0.22,1.52,1.16,1.31,2.1l-22.5,96.69 c-0.22,0.93-1.16,1.52-2.1,1.31l-5.95-1.39c-0.94-0.22-1.52-1.16-1.31-2.1l22.5-96.69C68.3,0.42,69.24-0.17,70.17,0.04L70.17,0.04 L70.17,0.04z"
          stroke={theme.body}
          stroke-width="3"
        /> */}
        {/* <path
          class="myPentagon"
          d="M166.978 12.8104L252.957 17.8491L274.734 101.177L202.214 147.638L135.617 93.0246L166.978 12.8104Z"
          stroke={theme.body}
          stroke-width="3"
        /> */}
        {/* <path
          class="letter"
          d="M41.1094 22.9688L48.1406 14.3906H4.125V1.73438H75.7969L34.7812 53.25L27.4688 62.3438H73.5V75H0.515625L41.1094 22.9688Z"
          fill={theme.body}
          stroke-width="2"
        />
        <path
          class="letter"
          d="M83.25 38.1562C83.25 32.625 84.2656 27.5625 86.2969 22.9688C88.3281 18.375 91.1719 14.4375 94.8281 11.1562C98.5156 7.84375 102.922 5.28125 108.047 3.46875C113.203 1.65625 118.906 0.75 125.156 0.75C131.375 0.75 137.062 1.65625 142.219 3.46875C147.375 5.28125 151.781 7.84375 155.438 11.1562C159.125 14.4375 161.984 18.375 164.016 22.9688C166.047 27.5625 167.062 32.625 167.062 38.1562C167.062 43.7188 166.047 48.8281 164.016 53.4844C161.984 58.1094 159.125 62.0938 155.438 65.4375C151.781 68.7812 147.375 71.3906 142.219 73.2656C137.062 75.1094 131.375 76.0312 125.156 76.0312C118.906 76.0312 113.203 75.1094 108.047 73.2656C102.922 71.3906 98.5156 68.7812 94.8281 65.4375C91.1719 62.0938 88.3281 58.1094 86.2969 53.4844C84.2656 48.8281 83.25 43.7188 83.25 38.1562ZM98.1094 38.1562C98.1094 41.9375 98.7812 45.3438 100.125 48.375C101.5 51.4062 103.391 54 105.797 56.1562C108.234 58.2812 111.094 59.9219 114.375 61.0781C117.688 62.2344 121.281 62.8125 125.156 62.8125C129.031 62.8125 132.609 62.2344 135.891 61.0781C139.203 59.9219 142.062 58.2812 144.469 56.1562C146.875 54 148.766 51.4062 150.141 48.375C151.516 45.3438 152.203 41.9375 152.203 38.1562C152.203 34.375 151.516 30.9844 150.141 27.9844C148.766 24.9844 146.875 22.4531 144.469 20.3906C142.062 18.2969 139.203 16.7031 135.891 15.6094C132.609 14.5156 129.031 13.9688 125.156 13.9688C121.281 13.9688 117.688 14.5156 114.375 15.6094C111.094 16.7031 108.234 18.2969 105.797 20.3906C103.391 22.4531 101.5 24.9844 100.125 27.9844C98.7812 30.9844 98.1094 34.375 98.1094 38.1562Z"
          fill={theme.body}
          stroke-width="2"
        />
        <path
          class="letter"
          d="M183.562 1.73438H220.828C226.453 1.73438 231.328 2.32813 235.453 3.51562C239.578 4.67188 243 6.35938 245.719 8.57812C248.438 10.7969 250.453 13.5 251.766 16.6875C253.109 19.875 253.781 23.4844 253.781 27.5156C253.781 30.2344 253.453 32.8281 252.797 35.2969C252.141 37.7344 251.125 39.9844 249.75 42.0469C248.406 44.1094 246.703 45.9531 244.641 47.5781C242.578 49.1719 240.156 50.5 237.375 51.5625L253.312 75H235.125L221.344 54.1875H220.922L198.422 54.1406V75H183.562V1.73438ZM221.203 41.2969C224.016 41.2969 226.469 40.9688 228.562 40.3125C230.688 39.6562 232.453 38.7344 233.859 37.5469C235.297 36.3594 236.359 34.9219 237.047 33.2344C237.766 31.5156 238.125 29.6094 238.125 27.5156C238.125 23.4219 236.719 20.2656 233.906 18.0469C231.094 15.7969 226.859 14.6719 221.203 14.6719H198.422V41.2969H221.203Z"
          fill={theme.body}
          stroke-width="2"
        />
        <path
          class="letter"
          d="M271.406 1.73438H333.797V14.7656H286.266V29.3906H328.359V41.625H286.266V62.0156H334.781V75H271.406V1.73438Z"
          fill={theme.body}
          stroke-width="2"
        />
        <path
          class="letter"
          d="M351.188 1.73438H366.047L411.703 25.6406V1.73438H426.562V75H411.703V41.3906L366.047 17.625V75H351.188V1.73438Z"
          fill={theme.body}
          stroke-width="2"
        />
        <path
          class="letter"
          d="M499.969 37.7812L471.797 1.73438H489.984L509.438 26.9531L528.984 1.73438H546.891L518.203 37.6406L547.875 75H529.641L508.781 48.6562L487.453 75H469.5L499.969 37.7812Z"
          fill={theme.body}
          stroke-width="2"
        /> */}
        <mask id="path-5-inside-1" fill={theme.body}>
          <path d="M81.5625 155.586C81.6406 155.586 81.7578 155.586 81.8359 155.664C82.2656 155.859 82.4219 156.328 82.2266 156.719C82.1875 156.797 77.6562 166.172 73.3203 176.484C73.75 176.484 74.1797 176.523 74.5703 176.562C76.6797 176.719 77.7344 177.305 77.2266 178.477C77.0703 178.828 76.6016 179.219 75.8203 179.648C75.4297 179.844 74.9609 179.688 74.7656 179.297C74.5312 178.906 74.6875 178.438 75.0781 178.203C75.5078 178.008 75.7422 177.852 75.7422 177.812C75.8984 177.539 75.1562 178.203 74.4141 178.125C73.9062 178.086 73.3203 178.086 72.6562 178.086C67.5 190.469 63.6719 203.008 67.1094 203.281C67.5781 203.32 67.9297 203.594 67.9688 204.023C68.0078 204.453 67.6562 204.844 67.2266 204.883C61.6016 205.234 65.8984 191.211 71.0938 178.086C70.3125 178.125 69.4141 178.125 68.4766 178.203C64.0234 178.398 58.3203 178.945 52.9688 179.531C48.8281 190.547 43.9844 201.68 43.2422 196.172C42.6172 191.602 39.375 188.398 37.0312 186.094C35.8594 184.961 34.9219 184.023 34.4922 183.125C33.9453 181.953 34.2578 180.977 35.8984 180.234C36.3672 180 43.7109 178.945 52.0312 178.047C54.2969 171.406 56.4844 164.922 57.3438 162.344C49.2969 163.047 2.86719 167.852 12.3828 184.57C12.5781 184.961 12.4219 185.469 12.0312 185.664C11.6406 185.859 11.1719 185.703 10.9766 185.312C0.507812 164.805 58.2422 160.664 58.3984 160.664C58.8672 160.625 59.2188 160.938 59.2578 161.406C59.2578 161.523 59.2578 161.602 59.2188 161.719C59.2188 161.758 56.6406 169.531 53.5547 177.852C58.75 177.305 64.1406 176.797 68.4375 176.602C69.6094 176.523 70.7422 176.484 71.7578 176.484C76.0547 165.742 80.7422 156.094 80.7812 156.016C80.9375 155.742 81.25 155.547 81.5625 155.586Z" />
          {/* <path d="M51.4453 179.688C43.6719 180.547 36.9922 181.484 36.5625 181.68C35.9766 181.953 35.8203 182.188 35.9766 182.461C36.25 183.086 37.1094 183.945 38.1641 184.961C40.625 187.422 42.9688 189.414 44.375 194.453C44.7656 195.938 48.2812 188.75 51.4453 179.688Z" /> */}
          <path d="M84.375 176.875C83.9844 176.758 83.5156 176.797 83.0469 176.914C82.1875 177.109 81.1719 177.656 80.3125 178.359C79.4141 179.062 78.6719 179.961 78.3984 180.859C78.2812 181.328 78.2422 181.758 78.3594 182.188C78.9062 184.688 83.0859 181.523 85.1953 179.688C85.4297 181.25 86.1328 183.242 88.3984 182.852C90.3516 182.5 92.4609 180.469 93.9453 179.062C94.5312 178.438 94.9609 178.008 95 177.969C94.4922 179.258 93.2812 183.516 93.2812 183.516C93.1641 183.945 93.4375 184.375 93.8672 184.492C94.2188 184.57 94.5703 184.414 94.7266 184.141C95.2734 183.438 100.898 176.289 100.938 177.305C101.055 180.742 101.562 182.656 103.164 183.359C104.727 184.023 106.992 183.242 110.586 180.859C110.977 180.625 111.055 180.156 110.82 179.766C110.586 179.414 110.078 179.297 109.727 179.531C106.641 181.602 104.805 182.344 103.828 181.914C102.93 181.484 102.578 179.844 102.5 177.07C102.383 173.164 98.2812 177.266 95.6641 180.391C96.3281 178.398 97.4609 175.82 96.5625 175.273C95.8594 175.078 93.6719 177.07 92.8125 177.891C91.4844 179.18 89.6094 181.016 88.125 181.289C86.4844 181.562 86.7188 178.008 86.7188 178.008C86.7578 177.812 86.6797 177.578 86.5234 177.422C86.2109 177.109 85.7031 177.07 85.3906 177.383C85.3906 177.383 85.1953 177.539 84.9609 177.812C85 177.422 84.7656 176.992 84.375 176.875Z" />
          {/* <path d="M84.2188 178.438C82.6562 179.883 80 182.266 79.9219 181.797C79.8828 181.68 79.8828 181.523 79.9219 181.328C80.1172 180.742 80.6641 180.117 81.2891 179.57C81.9922 179.023 82.7734 178.594 83.3984 178.438C83.6328 178.398 83.8281 178.398 83.9062 178.438C84.0234 178.438 84.1406 178.477 84.2188 178.438Z" /> */}
          <path d="M115.508 179.258C116.602 177.617 116.914 176.445 116.758 175.664C116.523 174.57 115.586 174.219 114.375 174.453C113.516 174.609 112.461 175.078 111.562 175.742C109.961 176.953 108.75 178.75 109.453 180.547C109.844 181.562 110.625 182.617 111.445 183.672C103.32 187.188 95.3125 191.523 106.797 191.25C113.359 191.133 115.312 189.609 115.273 187.539C115.234 186.523 114.648 185.43 113.906 184.336C118.281 182.578 122.617 181.055 122.656 181.016C123.086 180.859 123.281 180.43 123.125 180C123.008 179.57 122.539 179.375 122.109 179.531C122.07 179.531 117.695 181.094 112.969 183.047L112.773 182.812C112.031 181.797 111.25 180.781 110.938 179.961C110.547 178.984 111.406 177.852 112.5 177.031C113.203 176.523 114.023 176.133 114.648 176.016C114.961 175.977 115.195 175.938 115.195 176.016C115.273 176.367 115 177.109 114.18 178.359C113.906 178.711 114.023 179.219 114.375 179.492C114.727 179.727 115.234 179.609 115.508 179.258Z" />
          {/* <path d="M113.672 187.578C113.711 188.711 112.109 189.531 106.758 189.648C101.953 189.766 106.836 187.305 112.422 184.961C113.086 185.938 113.633 186.875 113.672 187.578Z" /> */}
          <path d="M198.32 155.117C200.547 155.156 202.109 155.312 202.734 155.664C203.32 155.977 203.516 156.445 203.32 156.992C202.461 159.57 200.273 160.664 200.273 160.664C199.883 160.859 199.414 160.703 199.219 160.312C199.023 159.922 199.18 159.453 199.57 159.219C199.57 159.219 200.898 158.555 201.641 156.953C200.859 156.758 199.102 156.68 196.68 156.758C193.75 156.836 189.766 157.07 185.273 157.5C164.375 159.336 131.797 163.945 130.703 165.977C130.742 165.977 130.898 165.664 131.094 165.742C131.797 165.898 132.852 166.055 134.375 166.211C138.359 166.562 145.352 166.758 156.172 166.68C156.602 166.68 156.953 167.031 156.953 167.461C156.953 167.93 156.602 168.281 156.172 168.281C145.312 168.359 138.281 168.164 134.219 167.773C132.617 167.656 131.445 167.461 130.703 167.266C129.531 166.953 129.023 166.484 129.062 165.82C129.102 165.742 129.102 165.664 129.102 165.625C129.805 163.047 163.594 157.773 185.117 155.898C189.609 155.469 193.594 155.234 196.641 155.156C197.227 155.156 197.773 155.117 198.32 155.117Z" />
          <path d="M166.289 160.508C166.406 160.508 166.445 160.508 166.445 160.508C166.875 160.586 167.148 161.055 167.031 161.484V161.523C166.992 161.602 164.492 169.18 161.484 177.383C166.797 176.914 171.211 176.719 171.211 176.719C171.68 176.68 172.031 177.031 172.07 177.461C172.07 177.773 171.914 178.086 171.641 178.203L169.609 179.258C169.219 179.453 168.75 179.297 168.555 178.906C168.477 178.789 168.438 178.633 168.477 178.477C166.523 178.594 163.828 178.75 160.859 179.023C156.68 190.156 151.797 201.562 151.016 195.977C150.391 191.406 147.148 188.242 144.805 185.938C143.672 184.805 142.695 183.867 142.305 182.969C141.758 181.758 142.031 180.82 143.672 180.039C146.172 178.906 153.477 178.047 159.922 177.5C161.992 171.523 163.945 165.703 164.883 162.852C164.531 163.203 164.023 163.711 163.359 164.414C163.086 164.727 162.578 164.766 162.266 164.453C161.914 164.141 161.875 163.633 162.188 163.32C164.531 160.742 165.859 160.508 166.289 160.508Z" />
          {/* <path d="M159.336 179.141C153.242 179.688 146.602 180.469 144.336 181.523C143.75 181.758 143.633 182.031 143.75 182.305C144.023 182.93 144.883 183.75 145.938 184.805C148.438 187.266 150.859 188.633 151.953 193.672C152.266 195.195 156.133 188.359 159.336 179.141Z" /> */}
          <path d="M173.867 179.883C172.148 177.383 173.906 174.688 177.773 171.992C185.078 166.914 199.492 162.188 212.969 159.258C226.367 156.367 238.711 155.195 241.992 157.266C242.578 157.617 242.695 158.203 242.227 158.984C238.711 164.57 225.859 169.375 216.523 172.852C211.094 174.883 206.797 176.484 205.859 177.773C205.352 178.477 205.508 179.102 206.25 179.648C207.422 180.508 210.938 181.172 214.961 181.953C217.344 182.422 219.883 182.891 222.188 183.477C223.516 183.789 224.648 184.102 225.625 184.453C219.883 187.266 213.828 190.586 214.023 192.344C214.141 193.477 215.742 193.984 219.57 193.594C223.789 193.125 227.852 191.953 230 190.43C230.938 189.766 231.562 189.023 231.797 188.203C232.031 187.305 231.797 186.367 230.938 185.508C230.586 185.117 230.078 184.766 229.453 184.375C230.391 183.906 231.289 183.516 232.109 183.125C234.18 182.148 235.781 181.406 236.289 181.094C236.641 180.859 236.758 180.352 236.523 180C236.328 179.609 235.82 179.492 235.43 179.727C235 180 233.477 180.703 231.445 181.68C230.273 182.227 228.984 182.812 227.617 183.477C226.289 182.93 224.609 182.422 222.539 181.914C220.156 181.328 217.617 180.82 215.273 180.391C211.641 179.688 208.516 179.062 207.383 178.477C208.516 177.539 212.344 176.133 217.07 174.375C226.641 170.781 239.805 165.898 243.555 159.805C244.648 158.086 244.297 156.797 242.812 155.898C239.18 153.633 226.367 154.727 212.617 157.695C198.984 160.664 184.336 165.469 176.836 170.664C172.188 173.906 170.156 177.383 172.539 180.781C172.812 181.133 173.281 181.25 173.672 180.977C174.023 180.742 174.102 180.234 173.867 179.883Z" />
          {/* <path d="M229.805 186.602C230.195 187.031 230.352 187.422 230.234 187.773C230.156 188.203 229.727 188.672 229.102 189.102C227.148 190.469 223.398 191.562 219.375 191.992C216.719 192.266 215.625 192.305 215.625 192.188C215.508 191.172 221.914 187.969 227.617 185.234C228.633 185.703 229.336 186.172 229.805 186.602Z" /> */}
          <path d="M289.023 160.703C290.781 156.836 291.25 154.531 291.133 153.398C291.055 152.773 290.82 152.344 290.469 152.148C290.039 151.875 289.57 151.875 289.102 152.188C288.516 152.539 287.852 153.398 287.5 154.531C287.383 154.922 287.148 155.742 286.875 156.914C285.273 162.891 281.25 178.047 275.977 186.719C273.281 186.211 270.742 185.703 268.398 185.234C261.367 183.906 256.367 182.93 255.898 183.125C254.531 183.672 253.984 184.688 254.062 185.977C254.141 186.875 254.57 187.93 255.234 188.945C255.898 189.922 256.836 190.898 257.93 191.797C261.406 194.609 266.758 196.484 271.641 193.75C273.438 192.773 275.117 190.898 276.68 188.477C293.945 191.719 315.898 195.586 317.383 193.477C317.812 192.812 317.578 192.109 316.484 191.367C316.133 191.094 315.625 191.172 315.391 191.562C315.117 191.914 315.195 192.383 315.586 192.656C315.781 192.773 316.211 192.383 316.055 192.578C315.117 193.906 294.336 190.195 277.578 186.992C282.109 179.336 285.586 167.617 287.539 160.586C287.578 160.82 287.734 161.016 287.969 161.094C288.359 161.289 288.828 161.094 289.023 160.703Z" />
          {/* <path d="M287.812 159.453C288.047 158.672 288.242 157.93 288.398 157.305C288.711 156.211 288.906 155.43 289.023 155C289.18 154.531 289.375 154.18 289.57 153.906C289.531 154.883 289.062 156.641 287.812 159.453Z" /> */}
          {/* <path d="M270.859 192.383C266.68 194.688 261.992 193.047 258.945 190.547C257.969 189.766 257.148 188.906 256.562 188.047C256.016 187.266 255.703 186.484 255.664 185.859C255.625 185.312 255.859 184.844 256.484 184.609C256.523 184.609 261.328 185.508 268.086 186.836C270.234 187.227 272.578 187.695 275.039 188.164C273.711 190.078 272.305 191.562 270.859 192.383Z" /> */}
          <path d="M296.25 179.219C295.859 178.828 294.727 178.711 293.828 181.094C293.047 182.969 292.422 185.547 294.883 185.82C295.938 185.938 297.148 185.078 298.242 183.75C298.906 182.969 299.531 182.031 300.039 181.055C302.031 181.562 304.961 181.602 309.102 180.82C309.531 180.742 309.844 180.352 309.727 179.922C309.688 179.492 309.258 179.18 308.828 179.258C305.078 179.961 302.461 179.961 300.742 179.57C301.133 178.555 301.445 177.539 301.562 176.602C301.719 175.234 301.445 174.023 300.586 173.32C299.766 172.617 298.789 172.734 297.93 173.32C297.07 173.867 296.367 174.961 296.133 176.133C295.898 177.383 296.211 178.711 297.383 179.766C297.695 180.039 298.086 180.273 298.516 180.508C298.086 181.328 297.539 182.109 297.031 182.734C296.25 183.672 295.508 184.258 295.039 184.219C293.672 184.062 296.211 180 296.211 179.961C296.328 179.766 296.445 179.414 296.25 179.219Z" />
          {/* <path d="M299.219 179.062C298.906 178.906 298.672 178.75 298.438 178.555C297.734 177.93 297.578 177.148 297.695 176.445C297.852 175.664 298.32 175 298.828 174.648C299.102 174.453 299.375 174.375 299.57 174.531C299.961 174.883 300.039 175.547 299.961 176.406C299.883 177.227 299.609 178.164 299.219 179.062Z" /> */}
          <path d="M308.359 182.344C308.32 182.109 310 180.312 311.758 178.438C312.617 177.5 313.516 176.562 314.219 175.703C315.195 175.625 316.172 175.508 316.992 175.312C316.484 175.781 315.781 176.445 315.078 177.383C312.383 180.898 308.711 185.664 309.18 187.109C309.414 187.812 310 188.047 311.016 187.734C312.93 187.07 315.156 185.977 317.109 184.805C319.727 183.281 322.031 181.641 322.734 180.859C323.047 180.508 323.047 180.039 322.695 179.727C322.383 179.414 321.875 179.453 321.562 179.766C320.938 180.469 318.828 181.992 316.328 183.438C314.531 184.492 312.539 185.469 310.82 186.094C311.445 184.727 314.219 181.094 316.367 178.359C317.344 177.031 319.688 174.727 319.062 173.672C318.789 173.203 318.359 173.086 317.812 173.438C317.422 173.672 316.523 173.867 315.469 173.984C315.859 173.359 316.055 172.773 315.898 172.383C315.586 171.523 314.531 171.328 312.383 172.109C310.703 172.695 309.805 173.203 309.453 173.633C308.75 174.414 308.945 175 309.844 175.43C310.195 175.625 310.781 175.703 311.445 175.781C311.641 175.781 311.836 175.781 312.07 175.781C311.602 176.289 311.094 176.797 310.586 177.344C308.555 179.531 306.523 181.68 306.797 182.734C306.914 183.164 307.344 183.398 307.773 183.281C308.203 183.203 308.477 182.734 308.359 182.344Z" />
          {/* <path d="M313.516 174.18C312.812 174.219 312.148 174.219 311.562 174.18H311.523C311.875 174.023 312.344 173.828 312.93 173.594C314.023 173.203 314.375 172.812 314.375 172.891C314.453 173.047 314.102 173.516 313.516 174.18Z" /> */}
          <path d="M322.617 182.305C323.203 182.266 323.945 181.992 324.609 181.602C325.312 181.172 325.977 180.547 326.367 179.727C326.68 179.102 326.836 178.359 326.758 177.578C326.641 176.445 326.055 176.055 325.234 176.133C324.727 176.211 324.141 176.562 323.555 177.109C323.086 177.578 322.578 178.164 322.109 178.867C320.938 180.703 320.312 183.047 321.875 184.062C323.633 185.156 326.367 183.203 329.023 181.289C330.625 180.156 332.148 179.062 333.164 178.711C333.047 178.945 332.852 179.219 332.695 179.492C331.797 180.82 330.742 182.422 331.367 183.711C331.719 184.492 332.383 184.57 333.203 184.18C334.141 183.75 335.469 182.383 336.719 181.133C337.891 179.961 338.594 178.594 338.75 178.711C338.75 178.711 338.594 179.609 338.438 180.586C338.047 182.539 337.656 184.688 339.219 185.273C341.25 186.055 348.164 180.703 348.203 180.664C348.555 180.391 348.594 179.883 348.32 179.57C348.086 179.219 347.578 179.141 347.227 179.414C347.188 179.414 340.977 184.258 339.805 183.789C339.492 183.672 339.766 182.188 340 180.859C340.273 179.414 340.508 178.086 339.766 177.5C338.828 176.719 337.266 178.281 335.586 180C334.57 181.016 333.516 182.109 332.812 182.578C332.93 181.992 333.516 181.133 334.023 180.391C334.375 179.844 334.727 179.336 334.922 178.906C335.234 178.125 335.195 177.5 334.453 177.148C334.375 177.148 334.336 177.109 334.258 177.109C332.695 176.719 330.43 178.359 328.125 180C325.859 181.602 323.555 183.242 322.734 182.695C322.617 182.617 322.539 182.5 322.5 182.305C322.539 182.305 322.578 182.305 322.617 182.305Z" />
          {/* <path d="M322.969 180.625C323.125 180.312 323.281 180 323.477 179.727C323.828 179.141 324.258 178.633 324.648 178.242C324.844 178.086 325.039 177.93 325.156 177.852C325.195 178.281 325.117 178.672 324.961 179.023C324.688 179.531 324.258 179.922 323.789 180.234C323.516 180.391 323.203 180.508 322.969 180.625Z" /> */}
          <path d="M348.398 180.039C348.32 179.414 348.438 178.867 348.672 178.438C348.828 178.125 349.102 177.891 349.414 177.695C349.727 177.5 350.117 177.383 350.547 177.344C351.172 177.266 351.914 177.383 352.695 177.695C352.148 178.164 351.523 178.672 350.898 179.141C349.102 180.547 347.5 181.836 347.383 182.93C347.344 183.828 347.969 184.453 349.648 184.57C349.805 184.57 349.922 184.57 350.039 184.609C344.141 189.453 341.133 195.273 340.273 199.922C339.805 202.422 339.961 204.609 340.586 206.133C341.289 207.891 342.617 208.906 344.375 208.789C346.328 208.672 348.867 206.992 351.641 203.125C355.156 198.32 356.602 194.531 356.797 191.562C356.914 189.844 356.641 188.438 356.094 187.227C355.508 186.055 354.688 185.117 353.75 184.453C353.633 184.336 353.477 184.258 353.32 184.141C355 183.008 356.836 181.953 358.906 181.016C359.336 180.859 359.492 180.391 359.336 179.961C359.141 179.57 358.672 179.375 358.281 179.57C355.781 180.703 353.594 181.953 351.68 183.359C351.016 183.125 350.391 183.008 349.766 182.969C349.219 182.93 348.984 183.125 348.984 183.047C349.023 182.656 350.391 181.562 351.875 180.391C352.852 179.648 353.828 178.867 354.648 178.086C354.727 178.047 354.766 177.969 354.844 177.852C355.039 177.5 354.922 176.992 354.531 176.797C353.086 175.938 351.641 175.625 350.391 175.742C349.727 175.82 349.102 176.016 348.594 176.328C348.047 176.641 347.578 177.109 347.266 177.656C346.875 178.359 346.68 179.219 346.797 180.195C346.836 180.625 347.227 180.977 347.656 180.938C348.125 180.859 348.438 180.469 348.398 180.039Z" />
          {/* <path d="M352.812 185.742C353.555 186.25 354.219 186.992 354.648 187.93C355.078 188.867 355.312 190.039 355.195 191.484C355 194.141 353.672 197.656 350.352 202.188C347.852 205.625 345.781 207.07 344.258 207.188C343.281 207.266 342.5 206.641 342.07 205.547C341.562 204.258 341.445 202.422 341.875 200.234C342.695 195.664 345.781 189.844 351.875 185.195C352.227 185.352 352.539 185.508 352.812 185.742Z" /> */}
          <path d="M363.438 176.875C363.047 176.758 362.578 176.797 362.109 176.914C361.25 177.109 360.234 177.656 359.375 178.359C358.477 179.062 357.734 179.961 357.461 180.859C357.344 181.328 357.305 181.758 357.422 182.188C357.969 184.688 362.148 181.523 364.258 179.688C364.492 181.25 365.195 183.242 367.461 182.852C369.414 182.5 371.523 180.469 373.008 179.062C373.594 178.438 374.023 178.008 374.062 177.969C373.555 179.258 372.344 183.516 372.344 183.516C372.227 183.945 372.5 184.375 372.93 184.492C373.281 184.57 373.633 184.414 373.789 184.141C374.336 183.438 379.961 176.289 380 177.305C380.117 180.742 380.625 182.656 382.227 183.359C383.789 184.023 386.055 183.242 389.648 180.859C390.039 180.625 390.117 180.156 389.883 179.766C389.648 179.414 389.141 179.297 388.789 179.531C385.703 181.602 383.867 182.344 382.891 181.914C381.992 181.484 381.641 179.844 381.562 177.07C381.445 173.164 377.344 177.266 374.727 180.391C375.391 178.398 376.523 175.82 375.625 175.273C374.922 175.078 372.734 177.07 371.875 177.891C370.547 179.18 368.672 181.016 367.188 181.289C365.547 181.562 365.781 178.008 365.781 178.008C365.82 177.812 365.742 177.578 365.586 177.422C365.273 177.109 364.766 177.07 364.453 177.383C364.453 177.383 364.258 177.539 364.023 177.812C364.062 177.422 363.828 176.992 363.438 176.875Z" />
          {/* <path d="M363.281 178.438C361.719 179.883 359.062 182.266 358.984 181.797C358.945 181.68 358.945 181.523 358.984 181.328C359.18 180.742 359.727 180.117 360.352 179.57C361.055 179.023 361.836 178.594 362.461 178.438C362.695 178.398 362.891 178.398 362.969 178.438C363.086 178.438 363.203 178.477 363.281 178.438Z" /> */}
          <path d="M394.922 179.297C393.516 177.344 391.445 177.734 389.727 178.945C388.906 179.531 388.203 180.312 387.695 181.055C387.148 181.875 386.836 182.773 386.914 183.477C386.992 184.492 387.695 185.117 389.18 184.961C391.836 184.688 394.18 182.852 395.703 181.289C395.508 183.789 395.781 184.844 396.953 185C397.812 185.156 398.945 184.492 400.703 183.477C401.914 182.773 403.438 181.875 405.352 180.938C405.742 180.742 405.938 180.273 405.742 179.883C405.547 179.492 405.039 179.297 404.648 179.492C402.695 180.469 401.094 181.367 399.883 182.07C398.438 182.93 397.5 183.477 397.188 183.438C396.992 183.398 397.148 182.148 397.578 178.867C397.617 178.555 397.5 178.242 397.227 178.086C396.875 177.852 396.367 177.93 396.133 178.281C396.133 178.281 395.742 178.867 395.039 179.609C395.039 179.492 395 179.375 394.922 179.297Z" />
          {/* <path d="M394.18 180.547C392.891 181.836 391.055 183.164 389.023 183.359C388.672 183.398 388.516 183.359 388.516 183.32C388.477 182.969 388.672 182.5 389.023 181.953C389.453 181.328 390.039 180.703 390.664 180.273C391.68 179.531 392.891 179.219 393.633 180.234C393.75 180.43 393.945 180.547 394.18 180.547Z" /> */}
        </mask>
        <path
          class="signature1"
          d="M81.5625 155.586C81.6406 155.586 81.7578 155.586 81.8359 155.664C82.2656 155.859 82.4219 156.328 82.2266 156.719C82.1875 156.797 77.6562 166.172 73.3203 176.484C73.75 176.484 74.1797 176.523 74.5703 176.562C76.6797 176.719 77.7344 177.305 77.2266 178.477C77.0703 178.828 76.6016 179.219 75.8203 179.648C75.4297 179.844 74.9609 179.688 74.7656 179.297C74.5312 178.906 74.6875 178.438 75.0781 178.203C75.5078 178.008 75.7422 177.852 75.7422 177.812C75.8984 177.539 75.1562 178.203 74.4141 178.125C73.9062 178.086 73.3203 178.086 72.6562 178.086C67.5 190.469 63.6719 203.008 67.1094 203.281C67.5781 203.32 67.9297 203.594 67.9688 204.023C68.0078 204.453 67.6562 204.844 67.2266 204.883C61.6016 205.234 65.8984 191.211 71.0938 178.086C70.3125 178.125 69.4141 178.125 68.4766 178.203C64.0234 178.398 58.3203 178.945 52.9688 179.531C48.8281 190.547 43.9844 201.68 43.2422 196.172C42.6172 191.602 39.375 188.398 37.0312 186.094C35.8594 184.961 34.9219 184.023 34.4922 183.125C33.9453 181.953 34.2578 180.977 35.8984 180.234C36.3672 180 43.7109 178.945 52.0312 178.047C54.2969 171.406 56.4844 164.922 57.3438 162.344C49.2969 163.047 2.86719 167.852 12.3828 184.57C12.5781 184.961 12.4219 185.469 12.0312 185.664C11.6406 185.859 11.1719 185.703 10.9766 185.312C0.507812 164.805 58.2422 160.664 58.3984 160.664C58.8672 160.625 59.2188 160.938 59.2578 161.406C59.2578 161.523 59.2578 161.602 59.2188 161.719C59.2188 161.758 56.6406 169.531 53.5547 177.852C58.75 177.305 64.1406 176.797 68.4375 176.602C69.6094 176.523 70.7422 176.484 71.7578 176.484C76.0547 165.742 80.7422 156.094 80.7812 156.016C80.9375 155.742 81.25 155.547 81.5625 155.586Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature2"
          d="M51.4453 179.688C43.6719 180.547 36.9922 181.484 36.5625 181.68C35.9766 181.953 35.8203 182.188 35.9766 182.461C36.25 183.086 37.1094 183.945 38.1641 184.961C40.625 187.422 42.9688 189.414 44.375 194.453C44.7656 195.938 48.2812 188.75 51.4453 179.688Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature3"
          d="M84.375 176.875C83.9844 176.758 83.5156 176.797 83.0469 176.914C82.1875 177.109 81.1719 177.656 80.3125 178.359C79.4141 179.062 78.6719 179.961 78.3984 180.859C78.2812 181.328 78.2422 181.758 78.3594 182.188C78.9062 184.688 83.0859 181.523 85.1953 179.688C85.4297 181.25 86.1328 183.242 88.3984 182.852C90.3516 182.5 92.4609 180.469 93.9453 179.062C94.5312 178.438 94.9609 178.008 95 177.969C94.4922 179.258 93.2812 183.516 93.2812 183.516C93.1641 183.945 93.4375 184.375 93.8672 184.492C94.2188 184.57 94.5703 184.414 94.7266 184.141C95.2734 183.438 100.898 176.289 100.938 177.305C101.055 180.742 101.562 182.656 103.164 183.359C104.727 184.023 106.992 183.242 110.586 180.859C110.977 180.625 111.055 180.156 110.82 179.766C110.586 179.414 110.078 179.297 109.727 179.531C106.641 181.602 104.805 182.344 103.828 181.914C102.93 181.484 102.578 179.844 102.5 177.07C102.383 173.164 98.2812 177.266 95.6641 180.391C96.3281 178.398 97.4609 175.82 96.5625 175.273C95.8594 175.078 93.6719 177.07 92.8125 177.891C91.4844 179.18 89.6094 181.016 88.125 181.289C86.4844 181.562 86.7188 178.008 86.7188 178.008C86.7578 177.812 86.6797 177.578 86.5234 177.422C86.2109 177.109 85.7031 177.07 85.3906 177.383C85.3906 177.383 85.1953 177.539 84.9609 177.812C85 177.422 84.7656 176.992 84.375 176.875Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature4"
          d="M84.2188 178.438C82.6562 179.883 80 182.266 79.9219 181.797C79.8828 181.68 79.8828 181.523 79.9219 181.328C80.1172 180.742 80.6641 180.117 81.2891 179.57C81.9922 179.023 82.7734 178.594 83.3984 178.438C83.6328 178.398 83.8281 178.398 83.9062 178.438C84.0234 178.438 84.1406 178.477 84.2188 178.438Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature5"
          d="M115.508 179.258C116.602 177.617 116.914 176.445 116.758 175.664C116.523 174.57 115.586 174.219 114.375 174.453C113.516 174.609 112.461 175.078 111.562 175.742C109.961 176.953 108.75 178.75 109.453 180.547C109.844 181.562 110.625 182.617 111.445 183.672C103.32 187.188 95.3125 191.523 106.797 191.25C113.359 191.133 115.312 189.609 115.273 187.539C115.234 186.523 114.648 185.43 113.906 184.336C118.281 182.578 122.617 181.055 122.656 181.016C123.086 180.859 123.281 180.43 123.125 180C123.008 179.57 122.539 179.375 122.109 179.531C122.07 179.531 117.695 181.094 112.969 183.047L112.773 182.812C112.031 181.797 111.25 180.781 110.938 179.961C110.547 178.984 111.406 177.852 112.5 177.031C113.203 176.523 114.023 176.133 114.648 176.016C114.961 175.977 115.195 175.938 115.195 176.016C115.273 176.367 115 177.109 114.18 178.359C113.906 178.711 114.023 179.219 114.375 179.492C114.727 179.727 115.234 179.609 115.508 179.258Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature6"
          d="M113.672 187.578C113.711 188.711 112.109 189.531 106.758 189.648C101.953 189.766 106.836 187.305 112.422 184.961C113.086 185.938 113.633 186.875 113.672 187.578Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature7"
          d="M198.32 155.117C200.547 155.156 202.109 155.312 202.734 155.664C203.32 155.977 203.516 156.445 203.32 156.992C202.461 159.57 200.273 160.664 200.273 160.664C199.883 160.859 199.414 160.703 199.219 160.312C199.023 159.922 199.18 159.453 199.57 159.219C199.57 159.219 200.898 158.555 201.641 156.953C200.859 156.758 199.102 156.68 196.68 156.758C193.75 156.836 189.766 157.07 185.273 157.5C164.375 159.336 131.797 163.945 130.703 165.977C130.742 165.977 130.898 165.664 131.094 165.742C131.797 165.898 132.852 166.055 134.375 166.211C138.359 166.562 145.352 166.758 156.172 166.68C156.602 166.68 156.953 167.031 156.953 167.461C156.953 167.93 156.602 168.281 156.172 168.281C145.312 168.359 138.281 168.164 134.219 167.773C132.617 167.656 131.445 167.461 130.703 167.266C129.531 166.953 129.023 166.484 129.062 165.82C129.102 165.742 129.102 165.664 129.102 165.625C129.805 163.047 163.594 157.773 185.117 155.898C189.609 155.469 193.594 155.234 196.641 155.156C197.227 155.156 197.773 155.117 198.32 155.117Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        <path
          class="signature8"
          d="M166.289 160.508C166.406 160.508 166.445 160.508 166.445 160.508C166.875 160.586 167.148 161.055 167.031 161.484V161.523C166.992 161.602 164.492 169.18 161.484 177.383C166.797 176.914 171.211 176.719 171.211 176.719C171.68 176.68 172.031 177.031 172.07 177.461C172.07 177.773 171.914 178.086 171.641 178.203L169.609 179.258C169.219 179.453 168.75 179.297 168.555 178.906C168.477 178.789 168.438 178.633 168.477 178.477C166.523 178.594 163.828 178.75 160.859 179.023C156.68 190.156 151.797 201.562 151.016 195.977C150.391 191.406 147.148 188.242 144.805 185.938C143.672 184.805 142.695 183.867 142.305 182.969C141.758 181.758 142.031 180.82 143.672 180.039C146.172 178.906 153.477 178.047 159.922 177.5C161.992 171.523 163.945 165.703 164.883 162.852C164.531 163.203 164.023 163.711 163.359 164.414C163.086 164.727 162.578 164.766 162.266 164.453C161.914 164.141 161.875 163.633 162.188 163.32C164.531 160.742 165.859 160.508 166.289 160.508Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature9"
          d="M159.336 179.141C153.242 179.688 146.602 180.469 144.336 181.523C143.75 181.758 143.633 182.031 143.75 182.305C144.023 182.93 144.883 183.75 145.938 184.805C148.438 187.266 150.859 188.633 151.953 193.672C152.266 195.195 156.133 188.359 159.336 179.141Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature10"
          d="M173.867 179.883C172.148 177.383 173.906 174.688 177.773 171.992C185.078 166.914 199.492 162.188 212.969 159.258C226.367 156.367 238.711 155.195 241.992 157.266C242.578 157.617 242.695 158.203 242.227 158.984C238.711 164.57 225.859 169.375 216.523 172.852C211.094 174.883 206.797 176.484 205.859 177.773C205.352 178.477 205.508 179.102 206.25 179.648C207.422 180.508 210.938 181.172 214.961 181.953C217.344 182.422 219.883 182.891 222.188 183.477C223.516 183.789 224.648 184.102 225.625 184.453C219.883 187.266 213.828 190.586 214.023 192.344C214.141 193.477 215.742 193.984 219.57 193.594C223.789 193.125 227.852 191.953 230 190.43C230.938 189.766 231.562 189.023 231.797 188.203C232.031 187.305 231.797 186.367 230.938 185.508C230.586 185.117 230.078 184.766 229.453 184.375C230.391 183.906 231.289 183.516 232.109 183.125C234.18 182.148 235.781 181.406 236.289 181.094C236.641 180.859 236.758 180.352 236.523 180C236.328 179.609 235.82 179.492 235.43 179.727C235 180 233.477 180.703 231.445 181.68C230.273 182.227 228.984 182.812 227.617 183.477C226.289 182.93 224.609 182.422 222.539 181.914C220.156 181.328 217.617 180.82 215.273 180.391C211.641 179.688 208.516 179.062 207.383 178.477C208.516 177.539 212.344 176.133 217.07 174.375C226.641 170.781 239.805 165.898 243.555 159.805C244.648 158.086 244.297 156.797 242.812 155.898C239.18 153.633 226.367 154.727 212.617 157.695C198.984 160.664 184.336 165.469 176.836 170.664C172.188 173.906 170.156 177.383 172.539 180.781C172.812 181.133 173.281 181.25 173.672 180.977C174.023 180.742 174.102 180.234 173.867 179.883Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature11"
          d="M229.805 186.602C230.195 187.031 230.352 187.422 230.234 187.773C230.156 188.203 229.727 188.672 229.102 189.102C227.148 190.469 223.398 191.562 219.375 191.992C216.719 192.266 215.625 192.305 215.625 192.188C215.508 191.172 221.914 187.969 227.617 185.234C228.633 185.703 229.336 186.172 229.805 186.602Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature12"
          d="M289.023 160.703C290.781 156.836 291.25 154.531 291.133 153.398C291.055 152.773 290.82 152.344 290.469 152.148C290.039 151.875 289.57 151.875 289.102 152.188C288.516 152.539 287.852 153.398 287.5 154.531C287.383 154.922 287.148 155.742 286.875 156.914C285.273 162.891 281.25 178.047 275.977 186.719C273.281 186.211 270.742 185.703 268.398 185.234C261.367 183.906 256.367 182.93 255.898 183.125C254.531 183.672 253.984 184.688 254.062 185.977C254.141 186.875 254.57 187.93 255.234 188.945C255.898 189.922 256.836 190.898 257.93 191.797C261.406 194.609 266.758 196.484 271.641 193.75C273.438 192.773 275.117 190.898 276.68 188.477C293.945 191.719 315.898 195.586 317.383 193.477C317.812 192.812 317.578 192.109 316.484 191.367C316.133 191.094 315.625 191.172 315.391 191.562C315.117 191.914 315.195 192.383 315.586 192.656C315.781 192.773 316.211 192.383 316.055 192.578C315.117 193.906 294.336 190.195 277.578 186.992C282.109 179.336 285.586 167.617 287.539 160.586C287.578 160.82 287.734 161.016 287.969 161.094C288.359 161.289 288.828 161.094 289.023 160.703Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature13"
          d="M287.812 159.453C288.047 158.672 288.242 157.93 288.398 157.305C288.711 156.211 288.906 155.43 289.023 155C289.18 154.531 289.375 154.18 289.57 153.906C289.531 154.883 289.062 156.641 287.812 159.453Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        {/* <path
          class="signature14"
          d="M270.859 192.383C266.68 194.688 261.992 193.047 258.945 190.547C257.969 189.766 257.148 188.906 256.562 188.047C256.016 187.266 255.703 186.484 255.664 185.859C255.625 185.312 255.859 184.844 256.484 184.609C256.523 184.609 261.328 185.508 268.086 186.836C270.234 187.227 272.578 187.695 275.039 188.164C273.711 190.078 272.305 191.562 270.859 192.383Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature15"
          d="M296.25 179.219C295.859 178.828 294.727 178.711 293.828 181.094C293.047 182.969 292.422 185.547 294.883 185.82C295.938 185.938 297.148 185.078 298.242 183.75C298.906 182.969 299.531 182.031 300.039 181.055C302.031 181.562 304.961 181.602 309.102 180.82C309.531 180.742 309.844 180.352 309.727 179.922C309.688 179.492 309.258 179.18 308.828 179.258C305.078 179.961 302.461 179.961 300.742 179.57C301.133 178.555 301.445 177.539 301.562 176.602C301.719 175.234 301.445 174.023 300.586 173.32C299.766 172.617 298.789 172.734 297.93 173.32C297.07 173.867 296.367 174.961 296.133 176.133C295.898 177.383 296.211 178.711 297.383 179.766C297.695 180.039 298.086 180.273 298.516 180.508C298.086 181.328 297.539 182.109 297.031 182.734C296.25 183.672 295.508 184.258 295.039 184.219C293.672 184.062 296.211 180 296.211 179.961C296.328 179.766 296.445 179.414 296.25 179.219Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature16"
          d="M299.219 179.062C298.906 178.906 298.672 178.75 298.438 178.555C297.734 177.93 297.578 177.148 297.695 176.445C297.852 175.664 298.32 175 298.828 174.648C299.102 174.453 299.375 174.375 299.57 174.531C299.961 174.883 300.039 175.547 299.961 176.406C299.883 177.227 299.609 178.164 299.219 179.062Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature17"
          d="M308.359 182.344C308.32 182.109 310 180.312 311.758 178.438C312.617 177.5 313.516 176.562 314.219 175.703C315.195 175.625 316.172 175.508 316.992 175.312C316.484 175.781 315.781 176.445 315.078 177.383C312.383 180.898 308.711 185.664 309.18 187.109C309.414 187.812 310 188.047 311.016 187.734C312.93 187.07 315.156 185.977 317.109 184.805C319.727 183.281 322.031 181.641 322.734 180.859C323.047 180.508 323.047 180.039 322.695 179.727C322.383 179.414 321.875 179.453 321.562 179.766C320.938 180.469 318.828 181.992 316.328 183.438C314.531 184.492 312.539 185.469 310.82 186.094C311.445 184.727 314.219 181.094 316.367 178.359C317.344 177.031 319.688 174.727 319.062 173.672C318.789 173.203 318.359 173.086 317.812 173.438C317.422 173.672 316.523 173.867 315.469 173.984C315.859 173.359 316.055 172.773 315.898 172.383C315.586 171.523 314.531 171.328 312.383 172.109C310.703 172.695 309.805 173.203 309.453 173.633C308.75 174.414 308.945 175 309.844 175.43C310.195 175.625 310.781 175.703 311.445 175.781C311.641 175.781 311.836 175.781 312.07 175.781C311.602 176.289 311.094 176.797 310.586 177.344C308.555 179.531 306.523 181.68 306.797 182.734C306.914 183.164 307.344 183.398 307.773 183.281C308.203 183.203 308.477 182.734 308.359 182.344Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature18"
          d="M313.516 174.18C312.812 174.219 312.148 174.219 311.562 174.18H311.523C311.875 174.023 312.344 173.828 312.93 173.594C314.023 173.203 314.375 172.812 314.375 172.891C314.453 173.047 314.102 173.516 313.516 174.18Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature19"
          d="M322.617 182.305C323.203 182.266 323.945 181.992 324.609 181.602C325.312 181.172 325.977 180.547 326.367 179.727C326.68 179.102 326.836 178.359 326.758 177.578C326.641 176.445 326.055 176.055 325.234 176.133C324.727 176.211 324.141 176.562 323.555 177.109C323.086 177.578 322.578 178.164 322.109 178.867C320.938 180.703 320.312 183.047 321.875 184.062C323.633 185.156 326.367 183.203 329.023 181.289C330.625 180.156 332.148 179.062 333.164 178.711C333.047 178.945 332.852 179.219 332.695 179.492C331.797 180.82 330.742 182.422 331.367 183.711C331.719 184.492 332.383 184.57 333.203 184.18C334.141 183.75 335.469 182.383 336.719 181.133C337.891 179.961 338.594 178.594 338.75 178.711C338.75 178.711 338.594 179.609 338.438 180.586C338.047 182.539 337.656 184.688 339.219 185.273C341.25 186.055 348.164 180.703 348.203 180.664C348.555 180.391 348.594 179.883 348.32 179.57C348.086 179.219 347.578 179.141 347.227 179.414C347.188 179.414 340.977 184.258 339.805 183.789C339.492 183.672 339.766 182.188 340 180.859C340.273 179.414 340.508 178.086 339.766 177.5C338.828 176.719 337.266 178.281 335.586 180C334.57 181.016 333.516 182.109 332.812 182.578C332.93 181.992 333.516 181.133 334.023 180.391C334.375 179.844 334.727 179.336 334.922 178.906C335.234 178.125 335.195 177.5 334.453 177.148C334.375 177.148 334.336 177.109 334.258 177.109C332.695 176.719 330.43 178.359 328.125 180C325.859 181.602 323.555 183.242 322.734 182.695C322.617 182.617 322.539 182.5 322.5 182.305C322.539 182.305 322.578 182.305 322.617 182.305Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature20"
          d="M322.969 180.625C323.125 180.312 323.281 180 323.477 179.727C323.828 179.141 324.258 178.633 324.648 178.242C324.844 178.086 325.039 177.93 325.156 177.852C325.195 178.281 325.117 178.672 324.961 179.023C324.688 179.531 324.258 179.922 323.789 180.234C323.516 180.391 323.203 180.508 322.969 180.625Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature21"
          d="M348.398 180.039C348.32 179.414 348.438 178.867 348.672 178.438C348.828 178.125 349.102 177.891 349.414 177.695C349.727 177.5 350.117 177.383 350.547 177.344C351.172 177.266 351.914 177.383 352.695 177.695C352.148 178.164 351.523 178.672 350.898 179.141C349.102 180.547 347.5 181.836 347.383 182.93C347.344 183.828 347.969 184.453 349.648 184.57C349.805 184.57 349.922 184.57 350.039 184.609C344.141 189.453 341.133 195.273 340.273 199.922C339.805 202.422 339.961 204.609 340.586 206.133C341.289 207.891 342.617 208.906 344.375 208.789C346.328 208.672 348.867 206.992 351.641 203.125C355.156 198.32 356.602 194.531 356.797 191.562C356.914 189.844 356.641 188.438 356.094 187.227C355.508 186.055 354.688 185.117 353.75 184.453C353.633 184.336 353.477 184.258 353.32 184.141C355 183.008 356.836 181.953 358.906 181.016C359.336 180.859 359.492 180.391 359.336 179.961C359.141 179.57 358.672 179.375 358.281 179.57C355.781 180.703 353.594 181.953 351.68 183.359C351.016 183.125 350.391 183.008 349.766 182.969C349.219 182.93 348.984 183.125 348.984 183.047C349.023 182.656 350.391 181.562 351.875 180.391C352.852 179.648 353.828 178.867 354.648 178.086C354.727 178.047 354.766 177.969 354.844 177.852C355.039 177.5 354.922 176.992 354.531 176.797C353.086 175.938 351.641 175.625 350.391 175.742C349.727 175.82 349.102 176.016 348.594 176.328C348.047 176.641 347.578 177.109 347.266 177.656C346.875 178.359 346.68 179.219 346.797 180.195C346.836 180.625 347.227 180.977 347.656 180.938C348.125 180.859 348.438 180.469 348.398 180.039Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature22"
          d="M352.812 185.742C353.555 186.25 354.219 186.992 354.648 187.93C355.078 188.867 355.312 190.039 355.195 191.484C355 194.141 353.672 197.656 350.352 202.188C347.852 205.625 345.781 207.07 344.258 207.188C343.281 207.266 342.5 206.641 342.07 205.547C341.562 204.258 341.445 202.422 341.875 200.234C342.695 195.664 345.781 189.844 351.875 185.195C352.227 185.352 352.539 185.508 352.812 185.742Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature23"
          d="M363.438 176.875C363.047 176.758 362.578 176.797 362.109 176.914C361.25 177.109 360.234 177.656 359.375 178.359C358.477 179.062 357.734 179.961 357.461 180.859C357.344 181.328 357.305 181.758 357.422 182.188C357.969 184.688 362.148 181.523 364.258 179.688C364.492 181.25 365.195 183.242 367.461 182.852C369.414 182.5 371.523 180.469 373.008 179.062C373.594 178.438 374.023 178.008 374.062 177.969C373.555 179.258 372.344 183.516 372.344 183.516C372.227 183.945 372.5 184.375 372.93 184.492C373.281 184.57 373.633 184.414 373.789 184.141C374.336 183.438 379.961 176.289 380 177.305C380.117 180.742 380.625 182.656 382.227 183.359C383.789 184.023 386.055 183.242 389.648 180.859C390.039 180.625 390.117 180.156 389.883 179.766C389.648 179.414 389.141 179.297 388.789 179.531C385.703 181.602 383.867 182.344 382.891 181.914C381.992 181.484 381.641 179.844 381.562 177.07C381.445 173.164 377.344 177.266 374.727 180.391C375.391 178.398 376.523 175.82 375.625 175.273C374.922 175.078 372.734 177.07 371.875 177.891C370.547 179.18 368.672 181.016 367.188 181.289C365.547 181.562 365.781 178.008 365.781 178.008C365.82 177.812 365.742 177.578 365.586 177.422C365.273 177.109 364.766 177.07 364.453 177.383C364.453 177.383 364.258 177.539 364.023 177.812C364.062 177.422 363.828 176.992 363.438 176.875Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature24"
          d="M363.281 178.438C361.719 179.883 359.062 182.266 358.984 181.797C358.945 181.68 358.945 181.523 358.984 181.328C359.18 180.742 359.727 180.117 360.352 179.57C361.055 179.023 361.836 178.594 362.461 178.438C362.695 178.398 362.891 178.398 362.969 178.438C363.086 178.438 363.203 178.477 363.281 178.438Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <path
          class="signature25"
          d="M394.922 179.297C393.516 177.344 391.445 177.734 389.727 178.945C388.906 179.531 388.203 180.312 387.695 181.055C387.148 181.875 386.836 182.773 386.914 183.477C386.992 184.492 387.695 185.117 389.18 184.961C391.836 184.688 394.18 182.852 395.703 181.289C395.508 183.789 395.781 184.844 396.953 185C397.812 185.156 398.945 184.492 400.703 183.477C401.914 182.773 403.438 181.875 405.352 180.938C405.742 180.742 405.938 180.273 405.742 179.883C405.547 179.492 405.039 179.297 404.648 179.492C402.695 180.469 401.094 181.367 399.883 182.07C398.438 182.93 397.5 183.477 397.188 183.438C396.992 183.398 397.148 182.148 397.578 178.867C397.617 178.555 397.5 178.242 397.227 178.086C396.875 177.852 396.367 177.93 396.133 178.281C396.133 178.281 395.742 178.867 395.039 179.609C395.039 179.492 395 179.375 394.922 179.297Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        />
        {/* <path
          class="signature26"
          d="M394.18 180.547C392.891 181.836 391.055 183.164 389.023 183.359C388.672 183.398 388.516 183.359 388.516 183.32C388.477 182.969 388.672 182.5 389.023 181.953C389.453 181.328 390.039 180.703 390.664 180.273C391.68 179.531 392.891 179.219 393.633 180.234C393.75 180.43 393.945 180.547 394.18 180.547Z"
          stroke={theme.body}
          stroke-width="2"
          mask="url(#path-5-inside-1)"
        /> */}
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html: `

      .signature1{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.5s;
			-o-animation: dash 1s linear forwards 0.5s;
			-moz-animation: dash 1s linear forwards 0.5s;
			animation: dash 1s linear forwards 0.5s;
		}
		.signature3{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.7s;
			-o-animation: dash 1s linear forwards 0.7s;
			-moz-animation: dash 1s linear forwards 0.7s;
			animation: dash 1s linear forwards 0.7s;
		}
		.signature5{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 0.9s;
			-o-animation: dash 1s linear forwards 0.9s;
			-moz-animation: dash 1s linear forwards 0.9s;
			animation: dash 1s linear forwards 0.9s;
		}
		.signature7{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.1s;
			-o-animation: dash 1s linear forwards 1.1s;
			-moz-animation: dash 1s linear forwards 1.1s;
			animation: dash 1s linear forwards 1.1s;
		}
		.signature8{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.3s;
			-o-animation: dash 1s linear forwards 1.3s;
			-moz-animation: dash 1s linear forwards 1.3s;
			animation: dash 1s linear forwards 1.3s;
		}
		.signature10{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.5s;
			-o-animation: dash 1s linear forwards 1.5s;
			-moz-animation: dash 1s linear forwards 1.5s;
			animation: dash 1s linear forwards 1.5s;
		}
		.signature12{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.7s;
			-o-animation: dash 1s linear forwards 1.7s;
			-moz-animation: dash 1s linear forwards 1.7s;
			animation: dash 1s linear forwards 1.7s;
		}
		.signature15{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 1.9s;
			-o-animation: dash 1s linear forwards 1.9s;
			-moz-animation: dash 1s linear forwards 1.9s;
			animation: dash 1s linear forwards 1.9s;
		}
    .signature17{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.1s;
			-o-animation: dash 1s linear forwards 2.1s;
			-moz-animation: dash 1s linear forwards 2.1s;
			animation: dash 1s linear forwards 2.1s;
		}
    .signature19{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.3s;
			-o-animation: dash 1s linear forwards 2.3s;
			-moz-animation: dash 1s linear forwards 2.3s;
			animation: dash 1s linear forwards 2.3s;
		}
    .signature21{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.5s;
			-o-animation: dash 1s linear forwards 2.5s;
			-moz-animation: dash 1s linear forwards 2.5s;
			animation: dash 1s linear forwards 2.5s;
		}
    .signature23{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.7s;
			-o-animation: dash 1s linear forwards 2.7s;
			-moz-animation: dash 1s linear forwards 2.7s;
			animation: dash 1s linear forwards 2.7s;
		}
    .signature25{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 1s linear forwards 2.9s;
			-o-animation: dash 1s linear forwards 2.9s;
			-moz-animation: dash 1s linear forwards 2.9;
			animation: dash 1s linear forwards 2.9s;
		}

		.signature{
			stroke-dasharray: 800;
			stroke-dashoffset: 800;
			-webkit-animation: dash 6s linear forwards 0.5s;
			-o-animation: dash 6s linear forwards 0.5s;
			-moz-animation: dash 6s linear forwards 0.5s;
			animation: dash 6s linear forwards 0.5s;
		}

		.letter{
			opacity: 0;
			-webkit-animation: fadein 2s linear forwards 2.5s;
			-o-animation: fadein 2s linear forwards 2.5s;
			-moz-animation: fadein 2s linear forwards 2.5s;
			animation: fadein 2s linear forwards 2.5s;
		}

		@-webkit-keyframes fadein{
			from{
				opacity: 0;
			}
			to{
				opacity: 1;
			}
		}

    .myPentagon {
      stroke-dasharray: 800;
      stroke-dashoffset: 800;
      -webkit-animation: dash 4s linear forwards 0.5s;
      -o-animation: dash 4s linear forwards 0.5s;
      -moz-animation: dash 4s linear forwards 0.5s;
      animation: dash 4s linear forwards 0.5s;
    }
    

		@-webkit-keyframes dash{
			from{
				stroke-dashoffset: 800;
			}
			to{
				stroke-dashoffset: 0;
			}
		}
    `,
            }}
          />
        </defs>
      </svg>
    );
  }
}

export default LogoLoader;
